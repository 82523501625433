'use strict';

$('#touristCarousel').owlCarousel({
    autoWidth: true,
    loop: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    margin: 10,
    nav: false
});

var owlPhotoGallery = $('.photoGalleryCarousel');
var options = {
    loop: false,
    items: 1,
    margin: 0,
    dots: false,
    nav: true
};
$('.photoGalleryCarousel').owlCarousel(options);
$('.photoGalleryCarousel2').owlCarousel(options);